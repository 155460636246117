.main-shop-component {
  padding: 12px 0;
  min-height: 50px;
}

.wrap-category-items {
  margin-bottom: 50px;
}

.title-category {
  margin-top: 20px;
  margin-bottom: 5px;
  color: #3E2723;
  font-size: 24px;
  font-weight: 500;
}

.category-viewer {
  margin: 12px;
  width: 300px;
  height: 200px;
  background: #fff;
  border-radius: 10px;
  max-width: calc(100vw - 24px);
  position: relative;
  overflow: hidden;

  &:hover {
    .main-info {
      opacity: 0;
    }
  }

  img.img-bg {
    position: absolute;
    border-radius: 10px;
    z-index: 0;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
  }

  .main-info {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    padding: 5px 0;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.3);
    color: #fff;

    .category-description {
      margin-top: 10px;
      text-align: center;
      text-shadow: #333333 1px 1px 0, #333333 -1px -1px 0,
      #333333 -1px 1px 0, #333333 1px -1px 0;
    }

    .category-name {
      text-align: center;
      font-size: 30px;
      text-shadow: #333333 1px 1px 0, #333333 -1px -1px 0,
      #333333 -1px 1px 0, #333333 1px -1px 0;
    }

    .category-count {
      margin-bottom: 14px;
      font-size: 18px;
      text-shadow: #333333 1px 1px 0, #333333 -1px -1px 0,
      #333333 -1px 1px 0, #333333 1px -1px 0;
    }
  }
}

.wrap-category-items-main {
  margin: 20px 0 30px 0;
}
