.no-outline {
  outline: none;

  &:active, &:focus {
    outline: none;
  }
}
.row-margin {
  *:not(:last-child) {
    margin-right: 10px;
  }
}

.info-block {
  &__title {
    color: #9E9E9E;
    font-size: 13px;
  }
  &__text {
    margin: 5px 0 10px;
  }
}

%result {
  text-transform: uppercase;
  padding: 3px 6px;
  height: 18px;
  font-size: 11px;
  line-height: normal;
  border-radius: 3px;
  color: #fff;
  white-space: nowrap;
  max-width: 110px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  vertical-align: middle;
  text-align: center;
}

.min-margin {
  margin: 0 5px;
}

.c-result {
  &--warning {
    @extend %result;
    background-color: #f44336;
  }

  &--success {
    @extend %result;
    background-color: #4caf50;
  }

  &--process {
    @extend %result;
    background-color: #ffba36;
  }

  &--default {
    @extend %result;
    background-color: gray;
  }
}

.white-gray {
  color: #9E9E9E;
}

.linkButton {
  font-size: 15px;
  margin: 10px;
  border-bottom: 1px dotted;
  color: #9e9e9e;
  cursor: pointer;

  &:hover {
    color: #3f51b5;
  }
}

.ws-tabs {
  md-tabs-wrapper {
    background: #0d47a1;
  }

  md-tab-item {
    color: #86a3d0;

    &.md-active {
      color: #fff;
    }
  }

  md-ink-bar {
    background-color: #4caf50;
  }
  md-prev-button, md-next-button {
    md-icon{
      color: #86a3d0;
    }
  }

}

.ws-two-row-autocomplete {
  li {
    line-height: 20px;
    height: auto;
    white-space: normal;
    padding: 10px;
  }

  .metadata {
    font-size: 12px;
  }
}

button {
  &.special-marks-button-default {
    color: #b2b2b2;
    background: #fff;
    width: auto;
    height: auto;
    padding: 4px;
    border-radius: 3px;
    border: 1px solid #e1e1e1;
    min-height: 0;
    line-height: 100%;
    min-width: 0;
    font-weight: bold;
    margin: 6px 2px;
    font-size: 12px;
  }

  &.special-marks-button-select, &.special-marks-button-select:hover, &.special-marks-button-default:hover {
    color: #0b48a3;
    background: #fff;
    width: auto;
    height: auto;
    padding: 4px;
    border-radius: 3px;
    border: 1px solid #bcc5e2;
    min-height: 0;
    line-height: 100%;
    min-width: 0;
    font-weight: bold;
    margin: 6px 2px;
    font-size: 12px;
  }
}

.chips-input-margin {

  position: relative;

  md-chips-wrap {
    margin-top: 23px;
    margin-bottom: 23px;
  }

  .chips-input-margin-label {
      position: absolute;
      top: 10px;
      left: 0;
      color: rgba(0,0,0,0.54);
      font-size: 12px;
  }
}

.instruction-wrapper {

  padding: 20px;

  .instruction-gif {
    float: left;
    margin: 20px 0;
    border: 1px solid #000;
    width: auto;
    max-width: 90%;
    max-height: calc(90vh - 80px);
  }
}

.overflow-auto {
  overflow: auto;
}

.h4-instruction {
  margin: 40px 0 0 0;
}

.p-instruction {
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  overflow: auto;
}

.main-section {
  overflow-x: auto;
}

.main-div {
  min-width: 600px !important;
}

.fix-height-80 {
  height: calc(80vh);
}

.main-layout {
  width: 100%;
  background: url('/assets/images/back/3.jpg');

  .main-wrapper {
    width: 90%;
    max-width: 1200px;
    min-height: calc(100vh - 116px);

    ui-view {
      min-height: calc(100vh - 116px);
    }
  }

  .main-wrapper-full {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
}

.about-us {
  margin: 30px;
  color: $main-color;
  font-size: 20px;

  img {
    width: 400px;
    max-width: 100%;
    margin-left: 10px;
    float: right;
    border-radius: 10px;
  }

  &--mini {
    font-size: 15px;

    img {
      width: 100%;
      margin-left: 0;
      margin-bottom: 10px;
      border-radius: 10px;
    }
  }
}

#scroll-wrapper {
  overflow-y: scroll; /* также может быть auto */
  -webkit-overflow-scrolling: touch;
}

.download-button {
  border: 2px solid $main-color;
  padding: 5px;
  border-radius: 10px;
  text-align: center;
  color: #795548 !important;
  font-size: 20px;
  font-weight: 500;
  margin: 10px 0;
}

a.a-about {
  width: 150px;
  cursor: pointer;

  .a-about {
    border: 2px solid $main-color;
    width: 150px;
    padding: 5px;
    border-radius: 10px;
    text-align: center;
    color: #795548 !important;
    font-size: 20px;
    font-weight: 500;
    margin: 10px 0;
  }

  &:hover {
    text-decoration: none !important;
  }
}

.about {
  padding: 24px 0;
  font-size: 20px;
  color: #795548;
}

.vacancies {
  color: #795548;
  padding: 24px 0;
}

.map {
  margin-top: 50px;
  margin-bottom: 16px;
  width: 100%;
  height: 300px;
}


.news-wrapper {
  padding: 24px 5px;
  margin: 0 0 15px 0;
  border-radius: 10px;
  color: $main-color;
  font-size: 18px;
  box-sizing: border-box;

  img {
    width: 400px;
    max-width: 100%;
    border-radius: 10px;

    &.right-img {
      float: right;
      margin: 10px 0 10px 10px;
    }

    &.left-img {
      float: right;
      margin: 10px 10px 10px 0;
    }

    &.top-img {
      margin-top: 0;
    }

    &.bottom-img {
      margin-bottom: 0;
    }
  }
}


.all-news-a {
  margin-left: 5px;
}
