.main-admin {
  padding: 20px;
  font-size: 15px;

}

.audit-admin {
  padding: 0;

  table.audit-table {
    width: calc(100% - 24px);
    margin: 20px 12px;

    thead tr th {
      background: #eeeeee;
    }

    thead tr th, tbody tr td {
      padding: 10px;
      border-bottom: 1px solid #e0e0e0;
    }
  }
}

.image-preview {
  height: 100px;
  margin-right: 10px;
}


.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.white-gray {
  color: #9E9E9E;
}

.no-outline {
  cursor: pointer;
  outline: none;

  &:active, &:focus {
    outline: none;
  }
}

.main-info-in-table {
  font-size: 16px;
}

.item-wrapper {
  margin-top: 20px;

  md-input-container.search-container {
    margin: 0;

    .md-errors-spacer {
      height: 0;
      min-height: 0;
    }
  }
}

.item-view {
  margin: 5px;
  border: 1px solid #e2e2e2;
  padding: 5px;

  .long-description-wrapper {
    max-height: 60px;
    overflow: auto;
    margin: 5px 0;
    border: 1px solid #000;
    padding: 5px;

    .long-description {

    }
  }

  &:hover {
    background: #fff;
  }

  .type-title {
    font-weight: 600 !important;
  }

  .action-item-view {
    margin: 0 0 5px 10px;
  }

  img {
    margin-left: 15px;
  }
}

.type-name {
  font-size: 12px !important;
  margin: 4px 0;
}

.order-admin {
  margin: 10px 0;
  padding: 10px;
  background: #fff;
  border-radius: 10px;
  min-height: 50px;
}

.order-item {
  border: 2px solid #e2e2e2;
  margin: 10px;
  padding: 5px;
  border-radius: 10px;
}

md-tabs {
  //min-height: 10000000px !important;
}

.button-select {
  border: 2px solid #000;
  margin: 10px;
  padding: 5px;
  border-radius: 10px;
  color: #000;

  &--select {
    background: #fff;
  }
}
