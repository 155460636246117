@charset "UTF-8";
/**
 *  Do not remove the comment below. It's the markers used by wiredep to inject
 *  sass dependencies when defined in the bower.json of your dependencies
 */
.oni-table {
  border: none;
  border-collapse: collapse;
  width: 100%; }
  .oni-table td, .oni-table th {
    padding: 12px; }
  .oni-table th {
    padding: 45px 12px 30px 12px; }
  .oni-table thead {
    text-transform: uppercase;
    color: #30a5ea;
    font-size: 10px; }
    .oni-table thead th {
      text-align: left; }
  .oni-table tbody tr.tr-in-payment {
    height: 95px;
    padding: 0; }
    .oni-table tbody tr.tr-in-payment td {
      padding: 0; }
    .oni-table tbody tr.tr-in-payment:hover {
      background: #fff;
      color: rgba(0, 0, 0, 0.870588); }
  .oni-table tbody tr {
    padding: 25px; }
    .oni-table tbody tr ng-md-icon {
      fill: #e6eef4; }
    .oni-table tbody tr:hover {
      background: #57677b;
      color: #fff; }
      .oni-table tbody tr:hover ng-md-icon {
        fill: #58bcf1; }
      .oni-table tbody tr:hover td.td-description {
        color: #4eb7f5; }
      .oni-table tbody tr:hover td.td-sans-light {
        color: #fff; }
      .oni-table tbody tr:hover td.td-sans {
        color: #fff; }
    .oni-table tbody tr td {
      border-bottom: 1px solid #eeeff2; }
      .oni-table tbody tr td .td-first {
        width: 100%;
        margin-top: 11px;
        height: 46px;
        line-height: 46px;
        font-family: SofiaProSemiBold;
        color: #465364;
        font-size: 20px;
        overflow: hidden;
        text-overflow: ellipsis; }
      .oni-table tbody tr td .td-second {
        width: 100%;
        height: 25px;
        font-family: OpenSans-Light;
        color: #57677b;
        font-size: 16px;
        overflow: hidden;
        text-overflow: ellipsis; }
        .oni-table tbody tr td .td-second .td-second-circle {
          width: 9px;
          height: 9px;
          border: 2px solid #57c1ff;
          border-radius: 11px;
          margin-top: 5px;
          margin-right: 15px;
          overflow: hidden;
          text-overflow: ellipsis; }
    .oni-table tbody tr td.td-description {
      font-family: SofiaProMedium;
      color: #465364;
      font-size: 19px; }
    .oni-table tbody tr td.td-last {
      border-bottom: 0px solid #eeeff2; }
    .oni-table tbody tr td.td-sans-light {
      font-family: OpenSans-Light;
      color: #465364; }
    .oni-table tbody tr td.td-sans {
      font-family: OpenSans-Light;
      color: #586473; }
    .oni-table tbody tr td.td-address-string {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }

.oni-transperent-dialog {
  background-color: initial;
  box-shadow: initial; }
  .oni-transperent-dialog .header {
    color: #fff;
    font-size: 55px;
    line-height: 55px;
    padding: 66px 0 66px 66px; }
  .oni-transperent-dialog .content {
    background-color: #fff;
    padding: 50px 0 70px 0; }
  .oni-transperent-dialog .secondary {
    background-color: #f1f5fa;
    padding: 50px 0px 70px 0px; }
  .oni-transperent-dialog .title {
    font-size: 22px;
    line-height: 40px;
    color: #57677b; }
  .oni-transperent-dialog .footerButton {
    padding: 40px;
    background-color: #fff; }
    .oni-transperent-dialog .footerButton button {
      height: 50px;
      border-radius: 50px;
      background-color: #56bffd !important; }

.oni-autocomplete {
  height: 63px;
  margin: 20px 0 20px 0; }
  .oni-autocomplete input {
    height: 63px !important;
    padding-left: 10px;
    padding-right: 10px; }

.oni-autocomplete-for-wrap {
  height: 63px;
  font-family: OpenSans-Light;
  line-height: 63px;
  color: #33353b;
  font-size: 18px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }
  .oni-autocomplete-for-wrap input {
    height: 63px !important;
    font-family: OpenSans-Light;
    line-height: 63px;
    color: #33353b;
    font-size: 18px; }
  .oni-autocomplete-for-wrap input:not(.md-input) {
    height: 63px !important;
    font-family: OpenSans-Light;
    line-height: 63px;
    color: #33353b;
    font-size: 18px;
    padding: 0 10px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }
  .oni-autocomplete-for-wrap md-autocomplete-wrap {
    height: 63px;
    width: 100%;
    line-height: 63px;
    border: 1px solid #e9e9e8;
    font-family: OpenSans-Light;
    color: #33353b;
    font-size: 18px;
    box-sizing: border-box;
    background: #fff;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }

.table-responsive-vertical {
  padding: 20px 12px;
  overflow-x: auto;
  overflow-y: hidden;
  min-width: 300px; }
  .table-responsive-vertical table {
    margin-bottom: 0;
    min-width: 100%;
    border: 1px solid #e1e1e1; }
    .table-responsive-vertical table thead tr th {
      padding: 10px;
      font-weight: 500;
      font-size: 13px;
      color: #9e9e9e;
      vertical-align: bottom;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      text-transform: uppercase;
      background-color: #f7f7f7;
      text-align: left; }
      .table-responsive-vertical table thead tr th.th-with-input {
        text-align: right; }
    .table-responsive-vertical table tbody tr {
      width: 100%;
      cursor: pointer; }
      .table-responsive-vertical table tbody tr:hover {
        background-color: #f7f7f7; }
    .table-responsive-vertical table tbody tr td {
      padding: 10px; }

.title-block {
  height: 54px;
  padding: 0 12px;
  background: #f9f9f9;
  border-top: 2px solid #000; }
  .title-block span.title {
    font-weight: bold; }

md-input-container.no-field-input-container {
  margin: 0;
  padding: 0;
  position: relative;
  top: 20px; }

.no-vertical-field {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.title-block {
  height: 65px;
  background: #fff;
  border-bottom: 1px solid #e0e0e0;
  padding: 0 24px; }
  .title-block span.title {
    color: #545454;
    font-weight: 500; }

.c-ws-table {
  width: 100%; }
  .c-ws-table thead {
    text-transform: uppercase;
    background-color: #f5f5f5;
    border-bottom: 1px solid #e1e1e1;
    line-height: 48px; }
  .c-ws-table th {
    font-size: 10pt;
    text-align: left;
    color: #7f7f7f;
    padding: 0 8px 0 8px; }
    .c-ws-table th:first-of-type {
      padding-left: 24px; }
  .c-ws-table td {
    padding: 16px 8px 16px 8px;
    border-bottom: 1px solid #e1e1e1;
    vertical-align: top;
    color: #282828;
    font-size: 14px; }
    .c-ws-table td:first-of-type {
      padding-left: 24px; }
    .c-ws-table td.text-right-align {
      text-align: right; }
  .c-ws-table tbody tr {
    background-color: #fff; }
    .c-ws-table tbody tr:hover {
      background-color: #f5f5f5; }

.main-admin {
  padding: 20px;
  font-size: 15px; }

.audit-admin {
  padding: 0; }
  .audit-admin table.audit-table {
    width: calc(100% - 24px);
    margin: 20px 12px; }
    .audit-admin table.audit-table thead tr th {
      background: #eeeeee; }
    .audit-admin table.audit-table thead tr th, .audit-admin table.audit-table tbody tr td {
      padding: 10px;
      border-bottom: 1px solid #e0e0e0; }

.image-preview {
  height: 100px;
  margin-right: 10px; }

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.white-gray {
  color: #9E9E9E; }

.no-outline {
  cursor: pointer;
  outline: none; }
  .no-outline:active, .no-outline:focus {
    outline: none; }

.main-info-in-table {
  font-size: 16px; }

.item-wrapper {
  margin-top: 20px; }
  .item-wrapper md-input-container.search-container {
    margin: 0; }
    .item-wrapper md-input-container.search-container .md-errors-spacer {
      height: 0;
      min-height: 0; }

.item-view {
  margin: 5px;
  border: 1px solid #e2e2e2;
  padding: 5px; }
  .item-view .long-description-wrapper {
    max-height: 60px;
    overflow: auto;
    margin: 5px 0;
    border: 1px solid #000;
    padding: 5px; }
  .item-view:hover {
    background: #fff; }
  .item-view .type-title {
    font-weight: 600 !important; }
  .item-view .action-item-view {
    margin: 0 0 5px 10px; }
  .item-view img {
    margin-left: 15px; }

.type-name {
  font-size: 12px !important;
  margin: 4px 0; }

.order-admin {
  margin: 10px 0;
  padding: 10px;
  background: #fff;
  border-radius: 10px;
  min-height: 50px; }

.order-item {
  border: 2px solid #e2e2e2;
  margin: 10px;
  padding: 5px;
  border-radius: 10px; }

.button-select {
  border: 2px solid #000;
  margin: 10px;
  padding: 5px;
  border-radius: 10px;
  color: #000; }
  .button-select--select {
    background: #fff; }

.no-outline {
  outline: none; }
  .no-outline:active, .no-outline:focus {
    outline: none; }

.row-margin *:not(:last-child) {
  margin-right: 10px; }

.info-block__title {
  color: #9E9E9E;
  font-size: 13px; }

.info-block__text {
  margin: 5px 0 10px; }

.c-result--warning, .c-result--success, .c-result--process, .c-result--default {
  text-transform: uppercase;
  padding: 3px 6px;
  height: 18px;
  font-size: 11px;
  line-height: normal;
  border-radius: 3px;
  color: #fff;
  white-space: nowrap;
  max-width: 110px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  vertical-align: middle;
  text-align: center; }

.min-margin {
  margin: 0 5px; }

.c-result--warning {
  background-color: #f44336; }

.c-result--success {
  background-color: #4caf50; }

.c-result--process {
  background-color: #ffba36; }

.c-result--default {
  background-color: gray; }

.white-gray {
  color: #9E9E9E; }

.linkButton {
  font-size: 15px;
  margin: 10px;
  border-bottom: 1px dotted;
  color: #9e9e9e;
  cursor: pointer; }
  .linkButton:hover {
    color: #3f51b5; }

.ws-tabs md-tabs-wrapper {
  background: #0d47a1; }

.ws-tabs md-tab-item {
  color: #86a3d0; }
  .ws-tabs md-tab-item.md-active {
    color: #fff; }

.ws-tabs md-ink-bar {
  background-color: #4caf50; }

.ws-tabs md-prev-button md-icon, .ws-tabs md-next-button md-icon {
  color: #86a3d0; }

.ws-two-row-autocomplete li {
  line-height: 20px;
  height: auto;
  white-space: normal;
  padding: 10px; }

.ws-two-row-autocomplete .metadata {
  font-size: 12px; }

button.special-marks-button-default {
  color: #b2b2b2;
  background: #fff;
  width: auto;
  height: auto;
  padding: 4px;
  border-radius: 3px;
  border: 1px solid #e1e1e1;
  min-height: 0;
  line-height: 100%;
  min-width: 0;
  font-weight: bold;
  margin: 6px 2px;
  font-size: 12px; }

button.special-marks-button-select, button.special-marks-button-select:hover, button.special-marks-button-default:hover {
  color: #0b48a3;
  background: #fff;
  width: auto;
  height: auto;
  padding: 4px;
  border-radius: 3px;
  border: 1px solid #bcc5e2;
  min-height: 0;
  line-height: 100%;
  min-width: 0;
  font-weight: bold;
  margin: 6px 2px;
  font-size: 12px; }

.chips-input-margin {
  position: relative; }
  .chips-input-margin md-chips-wrap {
    margin-top: 23px;
    margin-bottom: 23px; }
  .chips-input-margin .chips-input-margin-label {
    position: absolute;
    top: 10px;
    left: 0;
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px; }

.instruction-wrapper {
  padding: 20px; }
  .instruction-wrapper .instruction-gif {
    float: left;
    margin: 20px 0;
    border: 1px solid #000;
    width: auto;
    max-width: 90%;
    max-height: calc(90vh - 80px); }

.overflow-auto {
  overflow: auto; }

.h4-instruction {
  margin: 40px 0 0 0; }

.p-instruction {
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  overflow: auto; }

.main-section {
  overflow-x: auto; }

.main-div {
  min-width: 600px !important; }

.fix-height-80 {
  height: calc(80vh); }

.main-layout {
  width: 100%;
  background: url("/assets/images/back/3.jpg"); }
  .main-layout .main-wrapper {
    width: 90%;
    max-width: 1200px;
    min-height: calc(100vh - 116px); }
    .main-layout .main-wrapper ui-view {
      min-height: calc(100vh - 116px); }
  .main-layout .main-wrapper-full {
    width: 100%;
    max-width: 100%;
    min-width: 100%; }

.about-us {
  margin: 30px;
  color: #795548;
  font-size: 20px; }
  .about-us img {
    width: 400px;
    max-width: 100%;
    margin-left: 10px;
    float: right;
    border-radius: 10px; }
  .about-us--mini {
    font-size: 15px; }
    .about-us--mini img {
      width: 100%;
      margin-left: 0;
      margin-bottom: 10px;
      border-radius: 10px; }

#scroll-wrapper {
  overflow-y: scroll;
  /* также может быть auto */
  -webkit-overflow-scrolling: touch; }

.download-button {
  border: 2px solid #795548;
  padding: 5px;
  border-radius: 10px;
  text-align: center;
  color: #795548 !important;
  font-size: 20px;
  font-weight: 500;
  margin: 10px 0; }

a.a-about {
  width: 150px;
  cursor: pointer; }
  a.a-about .a-about {
    border: 2px solid #795548;
    width: 150px;
    padding: 5px;
    border-radius: 10px;
    text-align: center;
    color: #795548 !important;
    font-size: 20px;
    font-weight: 500;
    margin: 10px 0; }
  a.a-about:hover {
    text-decoration: none !important; }

.about {
  padding: 24px 0;
  font-size: 20px;
  color: #795548; }

.vacancies {
  color: #795548;
  padding: 24px 0; }

.map {
  margin-top: 50px;
  margin-bottom: 16px;
  width: 100%;
  height: 300px; }

.news-wrapper {
  padding: 24px 5px;
  margin: 0 0 15px 0;
  border-radius: 10px;
  color: #795548;
  font-size: 18px;
  box-sizing: border-box; }
  .news-wrapper img {
    width: 400px;
    max-width: 100%;
    border-radius: 10px; }
    .news-wrapper img.right-img {
      float: right;
      margin: 10px 0 10px 10px; }
    .news-wrapper img.left-img {
      float: right;
      margin: 10px 10px 10px 0; }
    .news-wrapper img.top-img {
      margin-top: 0; }
    .news-wrapper img.bottom-img {
      margin-bottom: 0; }

.all-news-a {
  margin-left: 5px; }

* {
  box-sizing: border-box; }

html, body, div, span, applet, object, iframe, p, h1, h2, h3, h4, h5, h6, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline; }

[role="button"] {
  cursor: pointer; }

p {
  margin: 0 0 10px; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a {
  text-decoration: none; }

a:link {
  color: #2196f3;
  text-decoration: none; }

a:visited {
  color: #2196f3; }

a:hover {
  color: #0d47a1;
  text-decoration: underline; }

a:active {
  color: #2196f3; }

md-datepicker {
  background-color: transparent; }

.md-chips .md-chip .md-chip-remove {
  border-radius: 50%;
  background-color: #8C8C8C;
  font-size: 10px;
  width: 16px;
  height: 16px;
  top: 8px;
  right: 8px;
  position: absolute; }
  .md-chips .md-chip .md-chip-remove md-icon {
    color: #e6e6e6;
    width: 100%;
    height: 100%;
    border-radius: 50%; }

ul {
  list-style: none; }

.no-exist-message {
  margin: 20px 0;
  font-size: 30px;
  text-align: center;
  color: #3E2723;
  font-weight: 500; }

.create-order-botton, .create-order-final {
  margin: 10px 0;
  font-size: 25px;
  padding: 5px;
  border: 2px solid #3E2723;
  border-radius: 10px;
  width: 100%;
  text-align: center; }

.create-order {
  margin: 10px 0;
  padding: 5px;
  border: 2px solid #3E2723;
  border-radius: 10px;
  width: 100%; }
  .create-order .delivery-info {
    width: 100%;
    text-align: center;
    color: #795548;
    font-size: 14px; }

.subcategory-public .no-exist-message {
  margin: 20px 0;
  font-size: 30px;
  text-align: center;
  color: #3E2723;
  font-weight: 500; }

.items-viewer {
  margin: 4px;
  padding: 4px;
  width: 300px;
  overflow-x: auto;
  min-height: 220px;
  background: #fff;
  border-radius: 10px;
  border: 2px solid #fff;
  max-width: calc(100% - 24px); }
  .items-viewer--select {
    border: 3px solid #795548; }
  .items-viewer .item-name {
    text-align: center;
    font-size: 24px; }
  .items-viewer .img-wrapper {
    width: 150px;
    max-width: 50%;
    height: 150px;
    overflow: hidden;
    border-radius: 10px; }
    .items-viewer .img-wrapper img.img-bg {
      height: 100%;
      width: auto;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 10px; }
  .items-viewer .main-info {
    min-height: 150px;
    max-width: 50%;
    padding: 0 5px; }
    .items-viewer .main-info .select-menu {
      max-width: 100%; }
    .items-viewer .main-info .bold-text {
      font-weight: 500;
      font-size: 20px; }
    .items-viewer .main-info .select-block {
      margin-bottom: 5px; }
    .items-viewer .main-info .select-item-view {
      border: 2px solid #3E2723;
      border-radius: 4px;
      padding: 3px;
      text-align: right !important;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 130px; }
      .items-viewer .main-info .select-item-view:hover {
        background: #3E2723;
        color: #fff; }
    .items-viewer .main-info md-icon.drop-down-select-item-view {
      color: #3E2723;
      min-height: 24px;
      min-width: 24px;
      max-height: 24px;
      max-width: 24px;
      height: 24px;
      width: 24px;
      font-size: 24px; }
    .items-viewer .main-info .link-icon {
      margin: 10px 0 0 0 !important;
      color: #3E2723; }
    .items-viewer .main-info .add-shopping {
      margin: 15px 0 0 0; }
      .items-viewer .main-info .add-shopping .shopping-text {
        color: #3E2723;
        font-size: 10px;
        text-align: right;
        margin-bottom: 4px; }
      .items-viewer .main-info .add-shopping md-icon {
        color: #3E2723;
        min-height: 40px;
        min-width: 40px;
        max-height: 40px;
        max-width: 40px;
        height: 40px;
        width: 40px;
        font-size: 40px; }
  .items-viewer .select-wrapper {
    background: rgba(0, 0, 0, 0.5);
    max-height: calc(100vh); }
    .items-viewer .select-wrapper .select-item {
      background: #3E2723;
      min-height: 40px;
      font-size: 25px;
      border-bottom: 1px solid #fff;
      color: #fff;
      padding: 5px; }
      .items-viewer .select-wrapper .select-item .price {
        font-size: 16px; }
  .items-viewer .description-item {
    margin-top: 5px; }
    .items-viewer .description-item md-icon {
      margin-right: 5px;
      margin-left: 0;
      color: #3E2723; }
    .items-viewer .description-item input.input-count {
      border: 0;
      width: 40px;
      min-width: 0; }
  .items-viewer .button-long-description {
    margin: 5px 0;
    padding: 5px;
    border-radius: 10px;
    border: 2px solid #795548;
    color: #795548;
    font-weight: 500; }
    .items-viewer .button-long-description:hover {
      background: #795548;
      color: #fff; }

.filter-category {
  margin: 12px 0; }
  .filter-category md-checkbox {
    margin-bottom: 0; }
  .filter-category md-checkbox.md-checked .md-icon,
  .filter-category md-checkbox.md-checked .md-ink-ripple {
    background-color: #3E2723 !important;
    color: #3E2723 !important; }
  .filter-category md-input-container {
    margin: 24px 0 24px 12px;
    width: 100px !important; }
    .filter-category md-input-container .md-errors-spacer {
      display: none; }

.menu-item-link {
  padding: 5px !important;
  color: #795548; }

.menu-item-add-basket {
  padding: 2px !important; }

.menu-item-add-basket__input-container {
  margin: 18px 0 0 0 !important; }

.button-add-basket, .button-copy {
  padding: 4px;
  background: #3E2723;
  color: #fff;
  border-radius: 4px;
  font-size: 20px;
  margin-right: 10px; }

.hidden-div {
  overflow: hidden;
  width: 0;
  height: 0; }

.select-wrapper {
  padding: 0;
  max-height: calc(100vh); }
  .select-wrapper .select-item {
    background: #3E2723;
    min-height: 40px;
    font-size: 25px;
    border-bottom: 1px solid #fff;
    color: #fff;
    padding: 5px; }
    .select-wrapper .select-item .price {
      font-size: 16px; }

.ch-tree .tree-item.selected {
  background-color: #C8EBFB;
  color: #335faf; }

.ch-tree .element {
  outline: inherit;
  padding: 10px;
  font-size: 15px;
  color: #000; }
  .ch-tree .element .title {
    width: 100%; }

.ch-tree .toggle-button {
  margin-right: 10px; }

.ws-pagination__pages {
  list-style-type: none; }
  .ws-pagination__pages li {
    display: inline-block;
    border-radius: 2px;
    text-align: center;
    vertical-align: top;
    height: 30px;
    box-sizing: 2px; }
  .ws-pagination__pages a {
    color: #444;
    font-size: 15px;
    padding: 0 10px;
    line-height: 30px;
    text-decoration: none; }

.ws-pagination__page--active {
  background-color: #0d47a1; }
  .ws-pagination__page--active a {
    color: #fff; }

.ws-pagination__page--disabled a {
  color: #9E9E9E; }

.ws-pagination__page--arrow-prev::after {
  content: '<';
  font-size: 25px; }

.ws-pagination__page--arrow-next::after {
  content: '>';
  font-size: 25px; }

.loader-inline {
  display: inline-block;
  vertical-align: middle; }
  .loader-inline .sk-circle {
    width: 20px;
    height: 20px;
    position: relative; }
    .loader-inline .sk-circle .sk-child {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0; }
      .loader-inline .sk-circle .sk-child:before {
        content: '';
        display: block;
        margin: 0 auto;
        width: 15%;
        height: 15%;
        background-color: #795548;
        border-radius: 100%;
        animation: sk-circleBounceDelay 1.2s infinite ease-in-out both; }
    .loader-inline .sk-circle .sk-circle2 {
      transform: rotate(30deg); }
      .loader-inline .sk-circle .sk-circle2:before {
        animation-delay: -1.1s; }
    .loader-inline .sk-circle .sk-circle3 {
      transform: rotate(60deg); }
      .loader-inline .sk-circle .sk-circle3:before {
        animation-delay: -1s; }
    .loader-inline .sk-circle .sk-circle4 {
      transform: rotate(90deg); }
      .loader-inline .sk-circle .sk-circle4:before {
        animation-delay: -0.9s; }
    .loader-inline .sk-circle .sk-circle5 {
      transform: rotate(120deg); }
      .loader-inline .sk-circle .sk-circle5:before {
        animation-delay: -0.8s; }
    .loader-inline .sk-circle .sk-circle6 {
      transform: rotate(150deg); }
      .loader-inline .sk-circle .sk-circle6:before {
        animation-delay: -0.7s; }
    .loader-inline .sk-circle .sk-circle7 {
      transform: rotate(180deg); }
      .loader-inline .sk-circle .sk-circle7:before {
        animation-delay: -0.6s; }
    .loader-inline .sk-circle .sk-circle8 {
      transform: rotate(210deg); }
      .loader-inline .sk-circle .sk-circle8:before {
        animation-delay: -0.5s; }
    .loader-inline .sk-circle .sk-circle9 {
      transform: rotate(240deg); }
      .loader-inline .sk-circle .sk-circle9:before {
        animation-delay: -0.4s; }
    .loader-inline .sk-circle .sk-circle10 {
      transform: rotate(270deg); }
      .loader-inline .sk-circle .sk-circle10:before {
        animation-delay: -0.3s; }
    .loader-inline .sk-circle .sk-circle11 {
      transform: rotate(300deg); }
      .loader-inline .sk-circle .sk-circle11:before {
        animation-delay: -0.2s; }
    .loader-inline .sk-circle .sk-circle12 {
      transform: rotate(330deg); }
      .loader-inline .sk-circle .sk-circle12:before {
        animation-delay: -0.1s; }

@keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    transform: scale(0); }
  40% {
    transform: scale(1); } }

.loader-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2500;
  display: block; }
  .loader-wrapper .spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 50px;
    height: 40px;
    text-align: center;
    font-size: 10px; }
    .loader-wrapper .spinner > div {
      background-color: #795548;
      height: 100%;
      width: 6px;
      display: inline-block;
      animation: sk-stretchdelay 1.2s infinite ease-in-out; }
    .loader-wrapper .spinner .rect2 {
      animation-delay: -1.1s; }
    .loader-wrapper .spinner .rect3 {
      animation-delay: -1.0s; }
    .loader-wrapper .spinner .rect4 {
      animation-delay: -0.9s; }
    .loader-wrapper .spinner .rect5 {
      animation-delay: -0.8s; }

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4); }
  20% {
    transform: scaleY(1); } }

.c-ch-tabs {
  background-color: #f7f7f7;
  display: flex;
  flex-direction: row;
  flex: 1; }
  .c-ch-tabs__menu {
    background-color: #fbfbfb;
    max-width: 200px; }
  .c-ch-tabs__menu-list {
    padding: 15px 0 15px 0;
    width: 100%; }
  .c-ch-tabs .c-ch-tabs__menu-list-item, .c-ch-tabs .c-ch-tabs__menu-list-item--active {
    padding: 10px 30px 10px 30px; }
  .c-ch-tabs__menu-list-item--active {
    color: #fff;
    background-color: #4474c9; }
  .c-ch-tabs__content {
    display: block;
    position: relative;
    overflow: auto;
    flex: 1; }

tr.ui-state-highlight {
  background: #f7f7f7;
  border: 2px dashed #e1e1e1;
  border-collapse: separate;
  border-radius: 4px;
  height: 61px;
  z-index: 100; }

.ui-sortable-helper {
  opacity: 0.6; }

.bottom-nav {
  height: 60px;
  background: #795548 !important;
  width: 100%;
  color: #fff;
  font-size: 10px;
  text-align: center; }

.side-nav {
  width: 275px;
  background-color: #f6f6f6;
  overflow: visible;
  display: flex; }
  .side-nav .md-icon-button {
    padding: 0;
    margin: 0; }
  .side-nav .user-info-side {
    background: #c0c9d8;
    padding: 20px;
    color: #585c63; }
    .side-nav .user-info-side .user-block .main-name {
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .side-nav .user-info-side .logout-button md-icon {
      color: #585c63;
      font-size: 35px;
      width: 35px;
      height: 35px;
      margin-top: 14px; }
    .side-nav .user-info-side .menu-icon {
      color: #fff;
      font-size: 25px;
      line-height: 10px; }
  .side-nav .tabs {
    width: 50px; }
    .side-nav .tabs .md-icon-button {
      width: 50px;
      height: 50px;
      border-radius: 0; }
      .side-nav .tabs .md-icon-button.selected {
        background-color: #4474c9; }
        .side-nav .tabs .md-icon-button.selected .tab-icon {
          color: #fff; }
      .side-nav .tabs .md-icon-button:not(.selected):hover {
        background-color: #eeeeee; }
    .side-nav .tabs .tab-icon {
      color: #888888; }
  .side-nav .nav-tree {
    color: #d4d4d4;
    font-size: 15pt; }
    .side-nav .nav-tree .toggle-button md-icon {
      color: #999da9;
      margin-right: 10px; }
    .side-nav .nav-tree .tree-item.selected {
      background-color: #C8EBFB;
      color: #335faf; }
    .side-nav .nav-tree .tree-item:hover {
      background-color: #f9f9f9; }
  .side-nav .title {
    font-size: 15px;
    font-weight: bold;
    padding: 15px 20px 15px 20px;
    color: #29313C; }
  .side-nav .tree {
    background-color: #fff;
    color: #29313C; }
    .side-nav .tree .ws-tree {
      color: #29313C; }
      .side-nav .tree .ws-tree .tree-item.selected {
        background-color: #C8EBFB;
        color: #335faf; }

md-content.tabs-container {
  border-right: 1px solid #EBEBEB; }

.avatar-wrapper {
  width: 50px;
  min-width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 5px;
  margin-left: 0;
  position: relative;
  overflow: hidden;
  border: 1px solid #e0e0e0; }
  .avatar-wrapper-min {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    min-width: 30px; }
  .avatar-wrapper img {
    width: auto;
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 0 !important; }

.avatar, .avatar-icon {
  width: 50px;
  height: 50px;
  font-size: 50px;
  border-radius: 50%;
  margin-right: 5px;
  margin-left: 0; }
  .avatar.icon-min, .avatar-icon.icon-min {
    width: 30px;
    height: 30px;
    font-size: 30px;
    margin-left: 10px; }

.main-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.top-nav {
  background: #795548 !important; }
  .top-nav .md-toolbar-tools {
    max-height: 100%;
    height: auto; }
    .top-nav .md-toolbar-tools .md-icon-button {
      min-width: 40px; }
  .top-nav__l-wrapper {
    display: block;
    position: relative;
    padding: 10px 0 10px 0; }
  .top-nav__header {
    float: left;
    white-space: nowrap;
    line-height: 40px; }
  .top-nav__actions {
    float: right;
    min-height: 40px; }
  .top-nav button.primary {
    background-color: #4caf50;
    color: #fff; }
    .top-nav button.primary:hover {
      background-color: #4caf50 !important; }

.contact-top {
  margin-right: 20px;
  font-size: 15px; }

.logo-img {
  height: 35px;
  width: auto; }

.menu-info {
  margin-right: 5px !important; }

.insta-icon {
  margin-right: 25px; }

md-menu-content.menu-info-content {
  padding: 10px !important;
  font-size: 15px;
  color: #795548; }

.top-href {
  position: fixed;
  bottom: 5px;
  left: 20px;
  background: #fff;
  padding: 10px;
  border-radius: 100%;
  border: 2px solid #795548;
  z-index: 2; }
  .top-href md-icon {
    color: #795548; }

.back-component {
  position: fixed;
  bottom: 5px;
  right: 20px;
  background: #fff;
  padding: 10px;
  border-radius: 100%;
  border: 2px solid #795548;
  z-index: 2; }
  .back-component md-icon {
    color: #795548; }
  .back-component__form {
    position: fixed;
    bottom: 5px;
    right: 20px;
    background: #fff;
    padding: 10px;
    border-radius: 10px;
    border: 2px solid #795548;
    z-index: 2;
    width: 300px;
    max-width: calc(100vw - 50px);
    height: 400px;
    max-height: calc(100vh - 100px); }

.form-wrapper {
  overflow: auto; }

.form__title span {
  color: #795548;
  font-weight: 500; }

.form__title md-icon {
  margin: 0;
  color: #795548; }

.send-form {
  margin: 10px 0;
  font-size: 25px;
  padding: 5px;
  border: 2px solid #3E2723;
  border-radius: 10px;
  width: 100%;
  text-align: center; }

.basket-component {
  position: relative; }
  .basket-component md-icon.basket-icon {
    min-height: 30px;
    min-width: 30px;
    max-height: 30px;
    max-width: 30px;
    height: 30px;
    width: 30px;
    font-size: 30px; }
  .basket-component .count-new-basket-items {
    position: absolute;
    bottom: -6px;
    left: -10px;
    width: 20px;
    height: 20px;
    border: 1px solid #795548;
    border-radius: 10px;
    background: #fff;
    color: #795548;
    font-size: 10px;
    font-weight: bold; }

.bread-crumbs-component {
  padding: 12px 16px 0 0; }
  .bread-crumbs-component .crumb {
    color: #795548; }
    .bread-crumbs-component .crumb:hover {
      color: #A1887F; }
  .bread-crumbs-component .now-crumb {
    color: #3E2723; }

.main-shop-component {
  padding: 12px 0;
  min-height: 50px; }

.wrap-category-items {
  margin-bottom: 50px; }

.title-category {
  margin-top: 20px;
  margin-bottom: 5px;
  color: #3E2723;
  font-size: 24px;
  font-weight: 500; }

.category-viewer {
  margin: 12px;
  width: 300px;
  height: 200px;
  background: #fff;
  border-radius: 10px;
  max-width: calc(100vw - 24px);
  position: relative;
  overflow: hidden; }
  .category-viewer:hover .main-info {
    opacity: 0; }
  .category-viewer img.img-bg {
    position: absolute;
    border-radius: 10px;
    z-index: 0;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%; }
  .category-viewer .main-info {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    padding: 5px 0;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.3);
    color: #fff; }
    .category-viewer .main-info .category-description {
      margin-top: 10px;
      text-align: center;
      text-shadow: #333333 1px 1px 0, #333333 -1px -1px 0, #333333 -1px 1px 0, #333333 1px -1px 0; }
    .category-viewer .main-info .category-name {
      text-align: center;
      font-size: 30px;
      text-shadow: #333333 1px 1px 0, #333333 -1px -1px 0, #333333 -1px 1px 0, #333333 1px -1px 0; }
    .category-viewer .main-info .category-count {
      margin-bottom: 14px;
      font-size: 18px;
      text-shadow: #333333 1px 1px 0, #333333 -1px -1px 0, #333333 -1px 1px 0, #333333 1px -1px 0; }

.wrap-category-items-main {
  margin: 20px 0 30px 0; }

.orders-component {
  position: relative; }
  .orders-component md-icon.basket-icon {
    min-height: 30px;
    min-width: 30px;
    max-height: 30px;
    max-width: 30px;
    height: 30px;
    width: 30px;
    font-size: 30px; }
  .orders-component .count-new-basket-items {
    position: absolute;
    bottom: -6px;
    left: -10px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background: #3f51b5;
    color: #fff;
    font-size: 10px;
    font-weight: bold; }

.search-component {
  position: relative;
  margin-right: 15px; }
  .search-component md-icon.search-icon {
    min-height: 30px;
    min-width: 30px;
    max-height: 30px;
    max-width: 30px;
    height: 30px;
    width: 30px;
    font-size: 30px; }

md-menu-content.menu-search-content {
  padding: 0 !important;
  font-size: 15px;
  color: #795548; }
  md-menu-content.menu-search-content md-input-container {
    margin-bottom: 0; }

.md-autocomplete-suggestions li {
  height: auto;
  padding: 3px 5px; }

.autocomplete-div-name {
  height: 20px;
  line-height: 20px; }

.autocomplete-div-description {
  height: 20px;
  line-height: 20px; }

.view-text {
  padding: 24px;
  color: #795548;
  font-size: 18px; }

.td-button-width {
  width: 145px; }
