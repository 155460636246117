.subcategory-public {

  .no-exist-message {
    margin: 20px 0;
    font-size: 30px;
    text-align: center;
    color: #3E2723;
    font-weight: 500;
  }


}

.items-viewer {
  margin: 4px;
  padding: 4px;
  width: 300px;
  overflow-x: auto;
  min-height: 220px;
  background: #fff;
  border-radius: 10px;
  border: 2px solid #fff;
  max-width: calc(100% - 24px);

  &--select {
    border: 3px solid $main-color;
  }

  .item-name {
    text-align: center;
    font-size: 24px;
  }

  .img-wrapper {
    width: 150px;
    max-width: 50%;
    height: 150px;
    overflow: hidden;
    border-radius: 10px;

    img.img-bg {
      height: 100%;
      width: auto;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 10px;
    }
  }

  .main-info {
    min-height: 150px;
    max-width: 50%;
    padding: 0 5px;

    .select-menu {
      max-width: 100%;
    }

    .bold-text {
      font-weight: 500;
      font-size: 20px;
    }

    .select-block {
      margin-bottom: 5px;
    }

    .select-item-view {
      border: 2px solid #3E2723;
      border-radius: 4px;
      padding: 3px;
      text-align: right !important;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 130px;

      &:hover {
        background: #3E2723;
        color: #fff;
      }
    }

    md-icon.drop-down-select-item-view {
      color: #3E2723;
      min-height: 24px;
      min-width: 24px;
      max-height: 24px;
      max-width: 24px;
      height: 24px;
      width: 24px;
      font-size: 24px;
      //margin-bottom: 7px;
    }

    .link-icon {
      margin: 10px 0 0 0 !important;
      color: #3E2723;
    }

    .add-shopping {
      margin: 15px 0 0 0;

      .shopping-text {
        color: #3E2723;
        font-size: 10px;
        text-align: right;
        margin-bottom: 4px;
      }

      md-icon {
        color: #3E2723;
        min-height: 40px;
        min-width: 40px;
        max-height: 40px;
        max-width: 40px;
        height: 40px;
        width: 40px;
        font-size: 40px;
      }
    }
  }

  .select-wrapper {
    background: rgba(0, 0, 0, 0.5);
    max-height: calc(100vh);

    .select-item {
      background: #3E2723;
      min-height: 40px;
      font-size: 25px;
      border-bottom: 1px solid #fff;
      color: #fff;
      padding: 5px;

      .price {
        font-size: 16px;
      }
    }
  }

  .description-item {
    margin-top: 5px;

    md-icon {
      margin-right: 5px;
      margin-left: 0;
      color: #3E2723;
    }

    input.input-count {
      border: 0;
      width: 40px;
      min-width: 0;
    }
  }

  .button-long-description {
    margin: 5px 0;
    padding: 5px;
    border-radius: 10px;
    border: 2px solid $main-color;
    color: $main-color;
    font-weight: 500;

    &:hover {
      background: $main-color;
      color: #fff;
    }
  }
}

.filter-category {
  margin: 12px 0;

  md-checkbox {
    margin-bottom: 0;
  }

  md-checkbox.md-checked .md-icon,
  md-checkbox.md-checked .md-ink-ripple {
    background-color: #3E2723 !important;
    color: #3E2723 !important;
  }

  md-input-container {
    margin: 24px 0 24px 12px;
    width: 100px !important;

    .md-errors-spacer {
      display: none;
    }
  }
}

.menu-item-link {
  padding: 5px !important;
  color: $main-color;
}

.menu-item-add-basket {
  padding: 2px !important;
}

.menu-item-add-basket__input-container {
  margin: 18px 0 0 0 !important;
}

.button-add-basket, .button-copy {
  padding: 4px;
  background: #3E2723;
  color: #fff;
  border-radius: 4px;
  font-size: 20px;
  margin-right: 10px;
}

.hidden-div {
  overflow: hidden;
  width: 0;
  height: 0;
}



.select-wrapper {
  padding: 0;
  max-height: calc(100vh);

  .select-item {
    background: #3E2723;
    min-height: 40px;
    font-size: 25px;
    border-bottom: 1px solid #fff;
    color: #fff;
    padding: 5px;

    .price {
      font-size: 16px;
    }
  }
}
