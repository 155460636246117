.bread-crumbs-component {

  padding: 12px 16px 0 0;

  .crumb {
    color: $main-color;

    &:hover {
      color: #A1887F;
    }
  }

  .now-crumb {
    color: #3E2723;
  }
}
