.search-component {
  position: relative;
  margin-right: 15px;

  md-icon.search-icon {
    min-height: 30px;
    min-width: 30px;
    max-height: 30px;
    max-width: 30px;
    height: 30px;
    width: 30px;
    font-size: 30px;
  }
}


md-menu-content.menu-search-content {
  padding: 0 !important;
  font-size: 15px;
  color: $main-color;

  md-input-container {
    margin-bottom: 0;
  }
}

.md-autocomplete-suggestions {
  li {
    height: auto;
    padding: 3px 5px;
  }
}

.autocomplete-div-name {
  height: 20px;
  line-height: 20px;
}

.autocomplete-div-description {
  height: 20px;
  line-height: 20px;
}
