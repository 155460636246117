/**
 *  Do not remove the comment below. It's the markers used by wiredep to inject
 *  sass dependencies when defined in the bower.json of your dependencies
 */
// bower:scss
// endbower
// injector
@import "modules/global/global.scss";
@import "modules/admin/styles/main.scss";
@import "modules/main/styles/main.scss";
@import "modules/main/styles/reset.scss";
@import "modules/shop/styles/shop.scss";
@import "modules/shop/styles/subcategory.scss";
@import "modules/global/components/chTree/chTree.scss";
@import "modules/global/components/wsPagination/wsPagination.scss";
@import "modules/global/directive/chSpinner/chSpinner.scss";
@import "modules/global/directive/chTabs/chTabs.scss";
@import "modules/global/directive/wsSortable/wsSortable.scss";
@import "modules/main/components/bottomNav/bottomNav.scss";
@import "modules/main/components/sideNav/sideNav.scss";
@import "modules/main/components/topNav/topNav.scss";
@import "modules/shop/components/backMail/backMail.scss";
@import "modules/shop/components/basket/basket.scss";
@import "modules/shop/components/breadCrumbs/breadCrumbs.scss";
@import "modules/shop/components/mainShop/mainShop.scss";
@import "modules/shop/components/orders/orders.scss";
@import "modules/shop/components/search/search.scss";
@import "modules/shop/modal/viewText/viewText.scss";
// endinjector

.td-button-width {
  width: 145px;
}
