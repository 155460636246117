.no-exist-message {
  margin: 20px 0;
  font-size: 30px;
  text-align: center;
  color: #3E2723;
  font-weight: 500;
}

.create-order-botton, .create-order-final {
  margin: 10px 0;
  font-size: 25px;
  padding: 5px;
  border: 2px solid #3E2723;
  border-radius: 10px;
  width: 100%;
  text-align: center;
}

.create-order {
  margin: 10px 0;
  padding: 5px;
  border: 2px solid #3E2723;
  border-radius: 10px;
  width: 100%;

  .delivery-info {
    width: 100%;
    text-align: center;
    color: $main-color;
    font-size: 14px;
  }
}
