.basket-component {
  position: relative;

  md-icon.basket-icon {
    min-height: 30px;
    min-width: 30px;
    max-height: 30px;
    max-width: 30px;
    height: 30px;
    width: 30px;
    font-size: 30px;
  }

  .count-new-basket-items {
    position: absolute;
    bottom: -6px;
    left: -10px;
    width: 20px;
    height: 20px;
    border: 1px solid $main-color;
    border-radius: 10px;
    background: #fff;
    color: $main-color;
    font-size: 10px;
    font-weight: bold;
  }
}
