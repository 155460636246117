.top-href {
  position: fixed;
  bottom: 5px;
  left: 20px;
  background: #fff;
  padding: 10px;
  border-radius: 100%;
  border: 2px solid $main-color;
  z-index: 2;

  md-icon {
    color: $main-color;
  }
}

.back-component {
  position: fixed;
  bottom: 5px;
  right: 20px;
  background: #fff;
  padding: 10px;
  border-radius: 100%;
  border: 2px solid $main-color;
  z-index: 2;

  md-icon {
    color: $main-color;
  }

  &__form {
    position: fixed;
    bottom: 5px;
    right: 20px;
    background: #fff;
    padding: 10px;
    border-radius: 10px;
    border: 2px solid $main-color;
    z-index: 2;
    width: 300px;
    max-width: calc(100vw - 50px);
    height: 400px;
    max-height: calc(100vh - 100px);
  }
}

.form-wrapper {
  overflow: auto;
}

.form {
  &__title {
    span {
      color: $main-color;
      font-weight: 500;
    }

    md-icon {
      margin: 0;
      color: $main-color;
    }
  }
}

.send-form {
  margin: 10px 0;
  font-size: 25px;
  padding: 5px;
  border: 2px solid #3E2723;
  border-radius: 10px;
  width: 100%;
  text-align: center;
}
